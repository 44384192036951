<template>
    <v-container>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>ABC Insumos</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
                            <v-icon>mdi-hammer-wrench </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
                            <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-autocomplete clearable :loading="loadingStages" v-model="stageId" @change="getInputs()" :items="stages" item-value='id' item-text='name'  outlined rounded dense class="mt-6 ml-2" label="Etapas" ></v-autocomplete>
                <v-spacer></v-spacer>

            </v-toolbar>
            <v-progress-linear v-if="loading_table" indeterminate ></v-progress-linear>
            <v-simple-table dense>
                <thead>

                    <tr>
                        <th class="text-left">
                            CÓDIGO
                        </th>
                        <th class="text-left">
                            INSUMOS
                        </th>
                        <th >
                            UNID.
                        </th>
                        <th class="text-right">
                            QUANTIDADE
                        </th>
                        <th class="text-right">
                            P UNIT. (R$)
                        </th>
                        <th class="text-right">
                            TOTAL
                        </th>

                    </tr>

                </thead>

                <tbody>
                    <tr v-for="input in inputs" :key="input.id">
                        <td>{{ input.input_group.code + '-' + input.full_code }}</td>
                        <td>{{  input.name }}</td>
                        <td>{{  input.unity }}</td>
                        <td class="text-right">{{ formatMoney(input.pivot.quantity) }}</td>
                        <td class="text-right"> {{ formatMoney(input.value_with_tax) }} </td>
                        <td class="text-right"> {{ formatMoney(input.sub_total) }} </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2 ;color: white"></td>
                        <td class="text-right" style="background:#1976d2; color: white;">Total: {{ formatMoney(calcTotal())
                        }}</td>
                    </tr>
                </tfoot>
            </v-simple-table>


        </v-card>

    </v-container>
</template>

<script>
import service from "../service";

export default {
    data: () => ({
        loadingStages:false,
        costId: null,
        stages:[],
        stageId : null,
        inputs: [],
        budgetId: null,
        loading_table:true,
        toolItems: [
            { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
        ],
    }),
    computed: {

    },
    watch: {

    },
    created() {
        this.costId = this.$route.params.cost_id;
        this.getStagesSelect();
        this.getInputs();
    },
    methods: {
        getStagesSelect(){
            service.getAbcInputsSelectStages(this.costId)
            .then((response)=>{
                this.stages = response.data
            }).finally(()=>{

            })
        },
        getInputs() {
            this.loading_table = true
            this.inputs = []
            service
                .getAbcInputs(this.costId, this.stageId)
                .then((result) => {
                    this.inputs = result.data;
                })
                .catch()
                .finally(()=>{
                    this.loading_table = false
                });
        },
        actionMenuTool(action) {
            switch (action) {
                case "export_excel":
                    service.ExportAbcInputs(this.costId,this.stageId)
                        .then((response) => {

                            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                            var fileLink = document.createElement('a');

                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'relatorio_abc_insumos.xlsx');
                            document.body.appendChild(fileLink);
                            fileLink.click();

                        }).catch((response) => {
                            console.log(response)
                        })
                    break;
            }
        },

        calcTotal() {
            let total = 0
            total = this.inputs.reduce((acumulator, current) => {
                return acumulator + current.value_with_tax * current.pivot.quantity;
            }, 0);

            return total;
        },

        formatMoney(number) {
            number = this.parseNumber(number);
            return number.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        parseNumber(number) {
            if (isNaN(number)) {
                return 0;
            }
            let result = parseFloat(number);
            return result;
        },

    },
};
</script>

