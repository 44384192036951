<template>
  <div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="meteringDetacheds" sort-by="corporate_name"
      class="elevation-1" :pageCount="numberOfPagesPaginate" :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Medições Avulsas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn rounded color="primary" dark class="mb-2" @click='createItem()'>
            <v-icon>mdi-plus</v-icon>
            MEDIÇÃO AVULSA
          </v-btn>

        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" icon class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" icon class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>


      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>


    <!-- ADICIONAR NOVAS MEDICOES -->
    <DialogContractorSummary @executed="initialize()" v-model="dialog" :metering_detached="this.editedItem" ></DialogContractorSummary>

    <MsgDelete :loading="loadingDelete" v-model="dialogDelete" @close="dialogDelete = false" @confirm="deleteItemConfirm()"></MsgDelete>

  </div>
</template>
<script>
import { mapActions } from 'vuex';
import DialogContractorSummary from './_components/DialogContractorSummary.vue';
import MsgDelete from '@/pages/Components/MsgDelete.vue';

export default {
  components: {
    DialogContractorSummary,
    MsgDelete,
  },
  data: () => ({
    loadingTable:false,
    loadingDelete:false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: '',
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'EMPREITEIRO',
        align: 'start',
        sortable: false,
        value: 'contractor.fantasy_name',
      },
      { text: 'CNPJ', value: 'contractor.cnpj' },
      { text: 'CPF', value: 'contractor.cpf' },
      { text: '', align: 'right', value: 'actions' },
    ],
    meteringDetacheds: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('MeteringDetached', ['index', 'destroy']),
    initialize() {
      const {
        page,
        itemsPerPage
      } = this.options;

      let params = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,

      }
      this.index(params).then((response) => {
        this.meteringDetacheds = response.data.data
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
      })
      this.meteringDetacheds = []
    },
    createItem() {
      this.dialog = true
    },

    editItem(item) {
      this.editedIndex = this.meteringDetacheds.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(meteringDetached) {
      this.editedIndex = this.meteringDetacheds.indexOf(meteringDetached)
      this.editedItem = Object.assign({}, meteringDetached)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loadingDelete = true
      this.destroy(this.editedItem.id).then(() => {
        this.meteringDetacheds.splice(this.editedIndex, 1)
        this.closeDelete()
        this.$toast.success('Apagado com sucesso.')
      }).finally(() => {
        this.loadingDelete = false
      })


    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
