<template>
  <v-container>
    <v-card :loading="loadingMain" :disabled="loadingMain">
      <v-toolbar flat>
        <v-toolbar-title>Orçamento</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" small dark fab v-bind="attrs" v-on="on">
              <v-icon>mdi-hammer-wrench </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in toolItems" :key="index" @click="actionMenuTool(item.action)">
              <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn color="primary" title="Cadastrar novo serviço" dark rounded class="mb-2" @click="showDialogNewStage">
          <v-icon left> mdi-plus </v-icon>Novo Serviço</v-btn>
      </v-toolbar>
      <!-- <v-progress-linear
                indeterminate
                color="primary"
                v-if='loadingMain'
            ></v-progress-linear> -->
      <!-- ETAPAS  -->
      <div v-for="stage in stages" :key="stage[0].id">
        <div class="title_table">
          <div style="float: left">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-3" small dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, i) in itemsMenuService" :key="i"
                  @click="actionMenuService(item.action, stage)">
                  <v-list-item-title><v-icon class="mr-2"> {{ item.icon }} </v-icon>{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div style="float: left">
            {{ stage[0].code + " - " + stage[0].name }}
          </div>
          <div style="float: right">Total: {{ calcTotal(stage[0].id) }}</div>
        </div>
        <!-- COMPOSICOES -->
        <v-card elevation="2">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class=" text-left">NOME</th>
                  <th class=" text-center">UNID.</th>
                  <th class=" text-center">QUANT.</th>
                  <th class=" text-right">P. UNIT.(R$)</th>
                  <th class=" text-right">SUB-TOTAL</th>
                </tr>
              </thead>

              <tbody>
                <tr class="pointer" v-for="composition in compositions[stage[0].id]" :key="composition.id"
                  @click="showModalEditComposition(composition)">

                  <td style="width:550px">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ composition.composition_group.code + "-" + composition.code + ' - ' +
                            composition.name.substring(0, 150) + stringLong(composition.name) }}
                        </div>
                      </template>
                      <span>
                        {{ composition.composition_group.code + "-" + composition.code + ' - ' + composition.name }}
                      </span>
                    </v-tooltip>


                  </td>
                  <td class="text-center">
                    <div style="width:120px">{{ composition.unity.trim() }}</div>
                  </td>
                  <td class="text-center">
                    <v-edit-dialog :return-value.sync="composition.pivot.quantity" large cancel-text="CANCELAR"
                      save-text="SALVAR" @save="saveQuantityService(composition)">
                      <div style=" width:120px" class="editable">
                        {{ formatMoney(composition.pivot.quantity) }}
                      </div>
                      <template v-slot:input>
                        <vuetify-money v-model="composition.pivot.quantity" :clearable="true" valueWhenIsEmpty="0"
                          :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 5,
                          }" />
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td style="width:120px" class="text-right">
                    <div style="width:120px">
                      {{ formatMoney(composition.total_with_tax) }}
                    </div>
                  </td>
                  <td class="text-right">
                    <div style="width:120px">
                      {{ formatMoney(composition.total_with_tax * composition.pivot.quantity) }}
                    </div>

                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
      <!-- barra total -->
      <div class="title_table" v-if="compositions.length != 0">
        <v-row>
          <v-col class="text-right">
            TOTAL : {{ formatMoney(grandTotal) }}</v-col>
        </v-row>
      </div>
    </v-card>
    <!-- tela pra adicionar ou editar novo Serviço -->
    <v-dialog v-model="dialogNewStage" fullscreen>
      <v-card>
        <BarTitle title="Adicionar Serviço" @close="dialogNewStage = false"></BarTitle>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn text x-large @click="dialogNewStage = false"> Fechar </v-btn>
          <v-btn x-large width='150' class="" color="primary" :disabled="loadingBtnSaveService"
            :loading="loadingBtnSaveService" @click="confirmSaveService()">Salvar
          </v-btn>
        </v-card-actions>
        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-text-field outlined v-if="editService" label="Etapa" readonly :value="editNameStage" filled></v-text-field>
          <v-autocomplete outlined v-else :loading="loadingSearchStage" filled :error-messages="errorStage"
            v-model="searchStage" :items="itemsStage" item-text="code_name" item-value="id"
            no-data-text="Nenhum registro encontrado" label="Selecione uma etapa" :search-input.sync="syncSearchStage">
          </v-autocomplete>

          <v-card outlined>
            <v-data-table :loading="loadingTableAddCompositionService" :headers="headersAddCompositionService"
              :items="itemsCompositionService" dense item-key="name" class="elevation-0 " :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: false,
              }">
              <template v-slot:item.order="props">
                <v-edit-dialog :return-value.sync="props.item.pivot.order" large save-text="salvar"
                  cancel-text="cancelar" @save="itemsCompositionServiceSort()">
                  <div class="editable">
                    {{ props.item.pivot.order }}
                  </div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">ORDEM</div>
                    <vuetify-money v-model="props.item.pivot.order" :clearable="true" valueWhenIsEmpty="0" :options="{
                      locale: 'en',
                      length: 11,
                      precision: 2,
                    }" />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.full_code="{ item }">
                {{ item.composition_group.code + "-" + item.code }}
              </template>

              <template v-slot:item.pivot.quantity="{ item }">
                {{ formatMoney(item.pivot.quantity) }}
              </template>
              <template v-slot:item.total_with_tax="{ item }">
                {{ formatMoney(item.total_with_tax) }}
              </template>

              <template v-slot:item.calc_total="{ item }">
                {{ formatMoney(item.total_with_tax * item.pivot.quantity) }}
              </template>

              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Composições</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn class="mx-2" fab dark small color="primary" @click="addComposition()">
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon color="error" title="Excluir composição" @click="removeCompositionService(item)">
                  mdi-delete
                </v-icon>
              </template>

              <template v-slot:no-data> Nenhum registro disponível </template>
            </v-data-table>
          </v-card>
        </v-card-text>


      </v-card>
    </v-dialog>
    <!-- fim etapa -->

    <!-- MSG CONIRMAR EDITAR SALVAR -->
    <v-dialog v-model="msgConfirmEditServiceSave" width='500'>
      <v-card>
        <BarTitle title="salvar Serviço" @close=" msgConfirmEditServiceSave = false"></BarTitle>
        <v-card-text>
          <h2 class='text-center'>Existem itens que não foram ordenados, deseja salvar mesmo assim? </h2>

        </v-card-text>
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn text large @click="msgConfirmEditServiceSave = false"> Fechar </v-btn>
          <v-btn large class="" color="primary" :loading="loadingBtnSaveService" @click="saveService()">Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- END MSG CONIRMAR EDITAR SALVAR -->

    <!-- tela pra adicionar novo compositions -->
    <v-dialog v-model="dialogNewComposition" width="900">
      <v-card>
        <v-card-title class="text-h5">Adicionar Composição
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-data-table :loading="loadingTableAddComposition" v-model="selectedAddComposition"
            :headers="headersAddComposition" :items="itemsAddComposition" dense :single-select="false" show-select
            :options.sync="paginationAddComposition" :server-items-length="paginationAddCompositionTotal"
            item-key="name" class="elevation-1" :footer-props="{
              itemsPerPageText: 'Itens por pagina',
              itemsPerPageOptions: [5, 10, 15],
              showFirstLastPage: true,
            }">
            <template v-slot:item.total_with_tax="{ item }">
              {{ formatMoney(item.total_with_tax) }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Composições</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field v-model="searchTextAddComposition" append-icon="mdi-magnify" label="Localizar"
                  @keyup.enter="searchAddComposition()" single-line @click:append="searchAddComposition()"
                  hide-details></v-text-field>
              </v-toolbar>
            </template>

            <template v-slot:item.full_code="{ item }">
              <div style="white-space:nowrap">
                {{ item.composition_group.code + "-" + item.code }}
              </div>
            </template>

            <template v-slot:no-data> Nenhum registro disponível </template>
          </v-data-table>
          <!-- composicoes selecionadas -->
          <v-data-table :loading="loadingTableAddCompositionService" :headers="headersSelectedAddComposition"
            :items="selectedCompositions" dense item-key="name" class="elevation-1 mt-6" :items-per-page="5"
            :footer-props="{
              itemsPerPageText: 'Itens por pagina',
              itemsPerPageOptions: [5, 10, 15],
              showFirstLastPage: false,
            }">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Composições Selecionadas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:item.pivot.quantity="props">
              <v-edit-dialog :return-value.sync="props.item.pivot.quantity" large cancel-text="CANCELAR"
                save-text="SALVAR">
                <div class="editable">
                  {{ formatMoney(props.item.pivot.quantity) }}
                </div>
                <template v-slot:input>
                  <vuetify-money v-model="props.item.pivot.quantity" :clearable="true" valueWhenIsEmpty="0" :options="{
                    locale: 'pt-BR',
                    length: 11,
                    precision: 2,
                  }" />
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.full_code="{ item }">
              {{ item.composition_group.code + "-" + item.code }}
            </template>

            <template v-slot:no-data> Nenhum composição selecionada. </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text class="mx-0 mt-3 mr-1" @click="dialogNewComposition = false">Fechar</v-btn>
          <v-btn color="primary" @click="addCompositionSelected">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim compositions -->

    <!--modal editar composicao -->
    <v-dialog v-model="dialogEditComposition" fullscreen>
      <v-card>
        <BarTitle title="Editar Composição" @close="dialogEditComposition = false"></BarTitle>
        <v-card-text>
          <v-form class="px-3" @submit.prevent="submit()">
            <v-row>
              <v-col cols="2">
                <v-text-field hide-details readonly outlined
                  :value="editedItem.composition_group.code + '-' + editedItem.code" label="Codigo"></v-text-field></v-col>
              <v-col><v-text-field hide-details readonly outlined v-model="editedItem.name"
                  label="Nome"></v-text-field></v-col>
              <v-col>
                <v-text-field hide-details readonly outlined v-model="editedItem.composition_group.name"
                  label="Grupo de composicão"></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-text-field hide-details readonly outlined v-model="editedItem.unity" label="Unidade"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card outlined>
                  <v-data-table :items-per-page="-1" :disable-pagination="true" hide-default-footer
                    :loading="loadingItemsInputsEditComposition" dense :headers="headersCompositionInputs"
                    :items="getInputs" sort-by="calories" class="elevation-0" :footer-props="{
                      itemsPerPageText: 'Itens por pagina',
                      itemsPerPageOptions: [5, 10, 15],
                      showFirstLastPage: false,
                    }">
                    <!-- <template v-slot:item.full_code="{ item }">
                                            {{ item.input_group.code + " - " + item.full_code }}
                                        </template> -->
                    <template v-slot:item.name="{ item }">
                      <div style="width:550px">{{ item.input_group.code + "-" + item.full_code + " - " + item.name }}
                      </div>
                    </template>

                    <!-- <template v-slot:item.tax="{ item }">
                                            {{ formatMoney(item.tax) }}
                                        </template> -->
                    <template v-slot:item.calc_total="{ item }">
                      {{
                        formatMoney(item.pivot.quantity * item.value)
                      }}
                    </template>

                    <template v-slot:item.pivot.quantity="props">
                      <v-edit-dialog :return-value.sync="props.item.pivot.quantity" large save-text="salvar"
                        cancel-text="cancelar" @save="saveEditQuantityInput(props.item)">
                        <div class="editable">
                          {{ formatMoneyPrecision3(props.item.pivot.quantity) }}
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">Coeficiente</div>
                          <vuetify-money v-model="props.item.pivot.quantity" :clearable="true" valueWhenIsEmpty="0"
                            :options="{
                              locale: 'pt-BR',
                              length: 11,
                              precision: 3,
                            }" />
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.value="props">
                      <v-edit-dialog :return-value.sync="props.item.value" large save-text="salvar"
                        cancel-text="cancelar" @save="saveEditValueInput(props.item)">
                        <div class="editable">
                          {{ formatMoneyPrecision3(props.item.value) }}
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">Valor Sem Encargos</div>
                          <vuetify-money v-model="props.item.value" :clearable="true" valueWhenIsEmpty="0" :options="{
                            locale: 'pt-BR',
                            length: 11,
                            precision: 3,
                          }" />
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.value_with_tax="{ item }">
                      {{ formatMoney(item.value_with_tax) }}
                    </template>

                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Insumos</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:no-data>Nenhum registro encontrado.</template>
                    <template v-slot:foot>
                      <tr>
                        <td class="tax back_tax" colspan='3'></td>
                        <td class="tax text-right back_tax">ENCARGOS:</td>
                        <td class="tax text-right back_tax">{{ formatMoneyPrecision3(calcTaxInput) }}</td>
                        <td class="tax back_tax"></td>
                      </tr>
                      <tr>
                        <td class="tax back_total" colspan='3'></td>
                        <td class="tax text-right back_total">TOTAL:</td>
                        <td class="tax text-right back_total">{{ formatMoneyPrecision3(calcTotalInput) }}</td>
                        <td class="tax back_total"></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card outlined>
                  <v-data-table :items-per-page="-1" :disable-pagination="true" hide-default-footer
                    :loading="loadingItemsCompositionsEditComposition" dense :headers="headersCompositionCompositions"
                    :items="getCompositions" sort-by="calories" class="elevation-0" :footer-props="{
                      itemsPerPageText: 'Itens por pagina',
                      itemsPerPageOptions: [5, 10, 15],
                      showFirstLastPage: false,
                    }">
                    <template v-slot:item.name="{ item }">
                      <div style="width:550px">{{ item.composition_group.code + "-" + item.code + ' - ' + item.name }}
                      </div>
                    </template>

                    <template v-slot:item.calc_total="{ item }">
                      {{
                        formatMoney(item.pivot.quantity * item.total)
                      }}
                    </template>

                    <template v-slot:item.pivot.quantity="props">
                      <v-edit-dialog :return-value.sync="props.item.pivot.quantity" large save-text="salvar"
                        cancel-text="cancelar" @save="saveEditQuantityComposition(props.item)">
                        <div class="editable">
                          {{ formatMoneyPrecision3(props.item.pivot.quantity) }}
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-h6">Coeficiente</div>
                          <vuetify-money v-model="props.item.pivot.quantity" :clearable="true" valueWhenIsEmpty="0"
                            :options="{
                              locale: 'pt-BR',
                              length: 11,
                              precision: 3,
                            }" />
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.total_with_tax="{ item }">
                      {{ formatMoney(item.total_with_tax) }}
                    </template>

                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Composicões</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                    </template>
                    <template v-slot:no-data>Nenhum registro encontrado.</template>
                    <template v-slot:foot>
                      <tr>
                        <td class="tax back_tax" colspan='3'></td>
                        <td class="tax text-right back_tax">ENCARGOS:</td>
                        <td class="tax text-right back_tax">{{ formatMoneyPrecision3(calcTaxComposition) }}</td>
                        <td class="tax back_tax"></td>
                      </tr>
                      <tr>
                        <td class="tax back_total" colspan='3'></td>
                        <td class="tax text-right back_total">TOTAL:</td>
                        <td class="tax text-right back_total">{{ formatMoneyPrecision3(calcTotalComposition) }}</td>
                        <td class="tax back_total"></td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <vuetify-money label="Total" outlined :readonly="true" v-model="editedItem.total" valueWhenIsEmpty="0"
                  :options="{
                    locale: 'pt-BR',
                    prefix: 'R$',
                    length: 11,
                    precision: 5,
                  }" />
              </v-col>

              <v-col>
                <vuetify-money label="Encargos" readonly outlined v-model="editedItem.tax" valueWhenIsEmpty="" :options="{
                  locale: 'pt-BR',
                  suffix: 'R$',
                  length: 11,
                  precision: 5,
                }" />
              </v-col>

              <v-col>
                <vuetify-money label="Total com encargos" outlined :readonly="true" v-model="editedItem.total_with_tax"
                  valueWhenIsEmpty="0" :options="{
                    locale: 'pt-BR',
                    prefix: 'R$',
                    length: 11,
                    precision: 5,
                  }" />
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="secondary" text class="mx-0 mt-3 mr-1"
                        @click="dialogEditComposition = false">Fechar</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--fim editar composicao -->


  </v-container>
</template>

<script>
import service from "./service";
import { mapGetters } from "vuex";
import BarTitle from '@/pages/Components/BarTitle.vue';
export default {
  components: {
    BarTitle
  },
  data: () => ({
    msgConfirmEditServiceSave: false,
    loadingMain: false,
    costId: null,
    selectedCompositions: [],
    loadingItemsInputsEditComposition: false,
    loadingItemsCompositionsEditComposition: false,
    itemsInputsEditComposition: [],
    itemsCompositionsEditComposition: [],
    btnLoadingSave: false,
    items_group_composition: [],
    dialogEditComposition: false,
    editedItem: {
      id: "",
      composition_group_id: "",
      code: "",
      name: "",
      unity: "",
      tax: "",
      total: "",
      total_with_tax: "",
      composition_group: { name: "" },
    },
    grandTotal: 0,
    paginationAddCompositionTotal: 0,
    editStageSelected: null,
    editNameStage: null,
    editService: false,
    loadingBtnSaveService: false,
    loadingTables: false,
    errorStage: [],
    selectCompositionQuantity: null,
    paginationAddComposition: {},
    searchTextAddComposition: "",
    errorQuantity: [],
    dialogNewStage: false,
    dialogNewComposition: false,
    loadingTableAddComposition: false,
    selectComposition: { name: "", pivot: {} },
    selectedAddComposition: [],
    itemsAddComposition: [],
    searchStage: null,
    syncSearchStage: null,
    itemsSearchStage: [],
    loadingSearchStage: false,
    itemsCompositionService: [],
    loadingTableAddCompositionService: false,
    showCompositionSelected: false,
    itemsService: [],
    stages: [],
    compositions: [],
    headers: [
      { text: "", width: 3, align: "start", value: "code", sortable: false },
      { text: "", sortable: false, value: "name" },
      { text: "R$", width: 4, align: "end", value: "value", sortable: false },
    ],
    headersCompositionInputs: [

      { text: "NOME", value: "name", sortable: false },
      { text: "UNID", value: "unity", sortable: false },
      { text: "COEF.", value: "pivot.quantity", sortable: false, align: "right", },
      { text: "P UNIT.(R$)", value: "value", sortable: false, align: "right" },
      { text: "SUB-TOTAL (R$)", value: "calc_total", sortable: false, align: "right" },
      { text: "", value: "actions", sortable: false },

    ],
    headersCompositionCompositions: [

      { text: "NOME", value: "name", sortable: false },
      { text: "UNID", value: "unity", sortable: false },
      { text: "COEF.", value: "pivot.quantity", sortable: false, align: "center" },
      { text: "P UNIT.(R$)", value: "total", sortable: false, align: "right" },
      { text: "SUB-TOTAL(R$)", value: "calc_total", sortable: false, align: "right" },
      { text: "", value: "actions", sortable: false },
    ],
    headersAddComposition: [
      {
        text: "Codigo",
        width: true,
        value: "full_code",
        sortable: false,
        align: "start",
      },
      { text: "Nome", value: "name", align: "start", sortable: false },
      { text: "Unidade", value: "unity", align: "start", sortable: false },
      {
        text: "Valor Unitário",
        value: "total_with_tax",
        align: "start",
        sortable: false,
      },
    ],
    headersAddCompositionService: [
      {
        text: "ORDEM",
        value: "order",
        sortable: false,
        align: "start",
      },
      {
        text: "CÓDIGO",
        width: true,
        value: "full_code",
        sortable: false,
        align: "start",
      },
      { text: "NOME", value: "name", align: "start", sortable: false },
      { text: "UNIDADE", value: "unity", align: "start", sortable: false },
      {
        text: "QUANT.",
        sortable: false,
        value: "pivot.quantity",
        align: "start",
      },
      {
        text: "P. UNIT.(R$)",
        sortable: false,
        value: "total_with_tax",
        align: "start",
      },
      {
        text: "SUB-TOTAL",
        sortable: false,
        value: "calc_total",
        align: "start",
      },
      { text: "", value: "actions", align: "end", sortable: false },
    ],
    headersSelectedAddComposition: [
      {
        text: "Codigo",
        width: true,
        value: "full_code",
        sortable: false,
        align: "start",
      },
      { text: "Nome", value: "name", align: "start", sortable: false },
      { text: "Unidade", value: "unity", align: "start", sortable: false },

      {
        text: "Valor Unitário",
        value: "total_with_tax",
        align: "start",
      },
      {
        text: "Quantidade",
        value: "pivot.quantity",
        align: "start",
      },
    ],
    itemsMenuService: [
      { title: "Editar", action: "edit", icon: "mdi-pencil" },
      { title: "Excluir", action: "delete", icon: "mdi-delete" },
    ],
    budgetId: null,
    toolItems: [
      { title: "Exportar Excel", action: "export_excel", icon: "mdi-file-excel" },
    ],
  }),
  computed: {
    ...mapGetters("Cost", ["getCostId"]),



    itemsStage() {
      this.itemsSearchStage.forEach((item) => {
        item.code_name = item.code + ' - ' + item.name
      });

      return this.itemsSearchStage
    },
    codeComplete(item) {
      return item.code
    },
    getInputs() {

      let inputs = this.itemsInputsEditComposition

      //colocar propriedade order
      inputs.map((input) => {
        input.order = parseInt(input.input_group.code + input.full_code)
      })

      //ordena pela propriedade order
      inputs.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      })

      return inputs
    },
    getCompositions() {

      let compositions = this.itemsCompositionsEditComposition

      //colocar propriedade order
      compositions.map((composition) => {
        composition.order = parseInt(composition.composition_group.code + composition.code)
      })

      //ordena pela propriedade order
      compositions.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      })

      return compositions
    },
    calcTaxInput() {
      const totaltax = this.getInputs.reduce((acc, input) => {
        return acc + parseFloat(input.pivot.quantity * (input.value * (input.tax / 100)))
      }, 0)
      return totaltax
    },
    calcTotalInput() {
      const total = this.getInputs.reduce((acc, input) => {
        return acc + parseFloat(input.pivot.quantity * input.value_with_tax)
      }, 0)
      return total
    },
    calcTaxComposition() {
      const totaltax = this.getCompositions.reduce((acc, input) => {
        return acc + parseFloat(input.pivot.quantity * (input.tax))
      }, 0)
      return totaltax
    },
    calcTotalComposition() {
      const total = this.getCompositions.reduce((acc, input) => {
        return acc + parseFloat(input.pivot.quantity * input.total_with_tax)
      }, 0)
      return total
    }
  },
  watch: {
    selectCompositionQuantity() {
      if (this.selectCompositionQuantity > 0) {
        this.errorQuantity = [];
      }
    },
    syncSearchStage(val) {
      //verifica se o testo é vazio
      this.errorStage = [];
      //faz a requisicao
      this.getAllStages(val);
    },
    selectedAddComposition() {

      if (this.selectedCompositions.length > this.selectedAddComposition.length) {
        //remove o elemento das composicoes selecionadas
        let item_filter = this.selectedCompositions.filter((item) => {
          let contain = false;
          this.selectedAddComposition.forEach((itemSelected) => {
            if (itemSelected.id == item.id) {
              contain = true;
            }
          }, contain);
          if (contain == true) {
            return item;
          }
        });

        this.selectedCompositions = JSON.parse(JSON.stringify(item_filter));
      } else {
        //adiciona elemento nas composicoes selecionadas
        let item_last = this.selectedAddComposition[this.selectedAddComposition.length - 1] //pega o ultimo elemento do array
        item_last.pivot = { quantity: "0" }; //insere a propiedade pivot.quantity no obejetos q nao tem
        this.selectedCompositions.push(JSON.parse(JSON.stringify(item_last)))
      }
      return
    },
    paginationAddComposition: {
      handler() {
        this.getAllComposition();
      },
    },
  },
  created() {
    this.costId = this.getCostId;
    this.getServices();
  },
  methods: {

    stringLong(composition_name) {
      if (composition_name.length > 150) {
        return '...'
      }
      return ''
    },
    itemsCompositionServiceSort() {
      //ordena a listar de composicoes pela coluna ordem
      this.sortOrder()

    },
    sortOrder() {
      this.itemsCompositionService.sort((a, b) => {
        if (parseFloat(a.pivot.order) > parseFloat(b.pivot.order)) return 1
        if (parseFloat(a.pivot.order) < parseFloat(b.pivot.order)) return -1
        return 0
      })
    },
    getServices() {
      this.loadingMain = true
      service
        .getServices(this.costId)
        .then((result) => {
          this.stages = result.data.stages;
          this.compositions = result.data.compositions;
          this.grandTotal = this.calcGrandTotal(this.compositions);
        })
        .catch()
        .finally(() => {
          this.loadingMain = false
        });
    },
    // saveEditOrderComposition(composition){
    //     composition.order =
    // },
    saveQuantityService(composition) {

      this.grandTotal = this.calcGrandTotal(this.compositions);
      service.setUpdateQuantity(composition.pivot.id, composition.pivot.quantity)

    },
    saveEditQuantityInput(input) {
      service
        .setEditQuantityInput(this.editedItem, input)
        .then(() => {
          this.$toast.success("Salvo com sucesso.");
        })
        .catch(() => {
          this.$toast.error(
            "Não foi possível salvar nova quantidade do insumo."
          );
        })
        .finally();

      this.recalcEditComposition();
    },

    saveEditValueInput(input) {
      service
        .setEditValueInput(this.editedItem, input)
        .then((response) => {
          input.value_with_tax = response.data.value_with_tax;

          this.$toast.success("Salvo com sucesso.");
        })
        .catch(() => {
          this.$toast.error("Não foi possível salvar novo valor do insumo.");
        })
        .finally(() => {
          this.recalcEditComposition();
        });
    },

    saveEditQuantityComposition(composition) {
      service
        .setEditQuantityComposition(this.editedItem, composition)
        .then(() => {
          this.$toast.success("Salvo com sucesso.");
        })
        .catch(() => {
          this.$toast.error(
            "Não foi possível salvar nova quantidade do insumo."
          );
        })
        .finally();
      this.recalcEditComposition();
    },

    showModalEditComposition(composition) {
      this.editedItem = composition;
      this.itemsInputsEditComposition = [];
      this.itemsCompositionsEditComposition = [];

      //carrega os insumos
      this.loadingItemsInputsEditComposition = true;
      service
        .getCompositionInputs(composition.id)
        .then((result) => {
          this.itemsInputsEditComposition = result.data;
        })
        .catch(() => {
          this.$toast.error("Não foi possível carregar os insumos.");
        })
        .finally(() => {
          this.loadingItemsInputsEditComposition = false;
        });

      //carrega as composicoes
      this.loadingItemsCompositionsEditComposition = true;
      service
        .getCompositionCompositions(composition.id)
        .then((result) => {
          this.itemsCompositionsEditComposition = result.data;
        })
        .catch(() => {
          this.$toast.error("Não foi possível carregar os insumos.");
        })
        .finally(() => {
          this.loadingItemsCompositionsEditComposition = false;
        });

      this.dialogEditComposition = true;
    },
    recalcEditComposition() {
      let total_inputs = this.itemsInputsEditComposition.reduce(
        (acumalator, current) => {
          return acumalator + current.pivot.quantity * current.value_with_tax;
        },
        0
      );

      let total_compositions = this.itemsCompositionsEditComposition.reduce(
        (acumalator, current) => {
          return acumalator + current.pivot.quantity * current.total_with_tax;
        },
        0
      );

      this.editedItem.total = total_compositions + total_inputs;
      this.editedItem.total_with_tax =
        this.editedItem.total +
        this.editedItem.total * (this.editedItem.tax / 100);
    },
    actionMenuService(action, stage) {
      switch (action) {
        case "edit":
          this.EditDialogStage(stage);
          break;
        case "delete":
          this.removeService(stage[0].id);
          break;
      }
    },

    actionMenuTool(action) {
      switch (action) {
        case "export_excel":
          service.exportExcel(this.costId)
            .then((response) => {

              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', 'export.xlsx');
              document.body.appendChild(fileLink);
              fileLink.click();

            }).catch((response) => {
              console.log(response)
            })
          break;
      }
    },


    calcTotal(stage_id) {

      //se não tem nenhuma composicao para essa estapa
      if (this.compositions[stage_id] != undefined) {
        let total = this.compositions[stage_id].reduce((acumulator, current) => {
          return acumulator + current.total_with_tax * current.pivot.quantity;
        }, 0);
        return this.formatMoney(total);
      } else {
        //caso nao tenha composicao
        return this.formatMoney(0);
      }


    },
    calcGrandTotal(compositions) {
      let total = 0;
      for (let key in compositions) {
        let subtotal = compositions[key].reduce((acumulator, current) => {
          return acumulator + current.total_with_tax * current.pivot.quantity;
        }, 0);

        total += subtotal;
      }

      return total;
    },
    saveEditComposition() {
      console.log(this.compositions);
    },
    confirmSaveService() {
      //valida se todos os items tem ordenamento
      let valided = true
      this.itemsCompositionService.forEach((item) => {
        if (parseFloat(item.pivot.order) == 0) {
          valided = false
        }
      })

      if (!valided) {
        this.msgConfirmEditServiceSave = true
      } else {
        this.saveService()
      }

    },
    saveService() {

      //fecha msg de confirmacao
      this.msgConfirmEditServiceSave = false

      if (this.editService) {
        //update
        let request = {
          compositions: this.itemsCompositionService,
          cost_id: this.costId,
          stage_id: this.editStageSelected.id,
        };
        this.loadingBtnSaveService = true;
        service
          .updateService(request)
          .then(() => {
            this.$toast.success("Serviço salvo com sucesso!");
            this.getServices();
            this.dialogNewStage = false;
          })
          .catch(() => {
            this.$toast.error("Não foi possível salvar esse serviço.");
          })
          .finally(() => {
            this.loadingBtnSaveService = false;
          });
      } else {
        //store

        if (this.searchStage == null) {
          this.errorStage = ["Etapa é um campo obrigatório"];
          return false;
        }

        if (this.itemsCompositionService.length == 0) {
          this.$swal({
            icon: "error",
            title: "Erro",
            text: "Nenhuma composição foi escolhida.",
            confirmButtonColor: "#3085d6",
          })
          return false;

        }

        let request = {
          compositions: this.itemsCompositionService,
          cost_id: this.costId,
          stage_id: this.searchStage,
        };
        this.loadingBtnSaveService = true;
        service
          .storeService(request)
          .then(() => {
            this.$toast.success("Serviço salvo com sucesso!");
            this.getServices();
            this.dialogNewStage = false;
          })
          .catch(() => {
            this.$toast.error("Não foi possível salvar esse serviço.");
          })
          .finally(() => {
            this.loadingBtnSaveService = false;
          });
      }
    },
    showDialogNewStage() {
      this.itemsCompositionService = [];
      this.syncSearchStage = null;
      this.dialogNewStage = true;
      this.editService = false;
      this.getAllStages("");
    },
    getAllStages(val) {
      this.loadingSearchStage = true;
      service
        .searchStage(val)
        .then((result) => {
          this.itemsSearchStage = result.data;
        })
        .catch()
        .finally(() => {
          this.loadingSearchStage = false;
        });
    },

    EditDialogStage(stage) {
      this.editStageSelected = stage[0];
      this.editNameStage = stage[0].name;
      this.itemsCompositionService = JSON.parse(
        JSON.stringify(this.compositions[stage[0].id])
      );

      //ordenar as composicoes
      this.sortOrder()

      this.editService = true;
      this.dialogNewStage = true;
    },
    addComposition() {
      this.selectedAddComposition = [];
      this.selectedCompositions = [];
      this.dialogNewComposition = true;
      this.selectCompositionQuantity = null;
      this.getAllComposition();
    },
    getAllComposition() {
      this.loadingTableAddComposition = true;
      const { page, itemsPerPage } = this.paginationAddComposition;
      service
        .getAllComposition(
          itemsPerPage || 10,
          page,
          this.searchTextAddComposition
        )
        .then((result) => {
          this.itemsAddComposition = result.data.data;
          this.paginationAddCompositionTotal = result.data.total;
        })
        .catch(() => {
          this.$toast.error("Não foi possível carregar os serviços.");
        })
        .finally(() => {
          this.loadingTableAddComposition = false;
        });
    },
    searchAddComposition() {
      this.paginationAddComposition = {};
      this.getAllComposition();
    },
    addCompositionSelected() {
      //validar quantidade

      let compositionsWrong = this.selectedCompositions.filter((item) => {
        return item.pivot.quantity == 0;
      });

      if (compositionsWrong.length > 0) {
        let msg =
          "Composicão: " +
          compositionsWrong[0].composition_group.code +
          "-" +
          compositionsWrong[0].code +
          "  " +
          compositionsWrong[0].name;
        this.$swal({
          icon: "error",
          title: "Quantidade não definida.",
          text: msg,
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      this.selectedCompositions.forEach((item) => {
        item.pivot.order = 0;
        this.itemsCompositionService.push(item);
      });

      this.dialogNewComposition = false;
    },

    formatMoney(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    formatMoneyPrecision5(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
      });
    },
    formatMoneyPrecision0(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    formatMoneyPrecision3(number) {
      number = this.parseNumber(number);
      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    },
    parseNumber(number) {
      if (isNaN(number)) {
        return 0;
      }
      let result = parseFloat(number);
      return result;
    },
    removeService(stage_id) {
      this.$swal({
        text: "Tem certeza que deseja deletar esse serviço ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, pode deletar !",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.showLoading();
          this.deleteService(stage_id);
        }
      });
    },
    removeCompositionService(item) {
      let index = this.itemsCompositionService.indexOf(item);
      this.itemsCompositionService.splice(index, 1);
    },
    deleteService(stage_id) {
      service
        .deleteService(this.costId, stage_id)
        .then(() => {
          this.$toast.success("Serviço deletado com sucesso!");
          this.getServices();
        })
        .catch(() => {
          this.$toast.error("Não foi possivel apagar esse serviço.");
        });
    },
  },
};
</script>

<style scoped>
.title_table {
  margin-top: 20px;
  width: 100%;
  height: 32px;
  background: #1976d2;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  padding-top: 3px;
}

.editable {
  color: #1976d2;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.tax {
  box-sizing: border-box;
  padding-right: 16px;
  padding-left: 16px;
  height: 30px;
  font-weight: bold;
}

.back_tax {
  background: lightgray;
}

.back_total {
  background: #1976d2;
  color: white;
}
</style>
