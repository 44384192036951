import axios from "axios"

const route = '/physical_progress/metering_detached';

export default {
    namespaced: true,

    state: () => ({
        services:[]
    }),
    getters: {
        getServices(state){
            return state.services
        }
    },
    mutations: {
        addService(state,service){
            state.services.push(service)
        },
        removeService(state,service){
            const index = state.services.indexOf(service);
            state.services.splice(index, 1);
        },
        setService(state, services){
            state.services = services
        }

    },
    actions: {
        index(context, data) {
            let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage || 10}&search=${data.search}`
            return axios.get(`${route}${pagination}`)
        },

        destroy(context, meteringDetachedId){
            return axios.delete(route+'/'+meteringDetachedId)
        },

        update(context,data){
            return axios.put(route+'/'+data.metering_detached_id, data)
        },

        //contractor summary
        // getContractSummary(context, contractor_id) {
        //     return axios.get(route + '/metering/contractor_summary/' + contractor_id)
        // },

        store(context, data) {
            return axios.post(route  ,data)
        },

        approvedServices(context,contractor_id){
            return axios.post(route + '/metering/approved_services/' + contractor_id)
        },

        //historico das medicoes
        // getHistoricMetering(){
        //     return axios.get(route + '/metering/historic_metering')
        // },

        //exportar pdf
        // getExportPdf(context,contractor){
        //     return axios.get(route + '/metering/export_pdf/' + contractor.id,{responseType: 'blob'})
        // },

        getContractors(){
            return axios.get('helper/get_all_contractor');
        },

        //carregar os custos
        getCost(){
            return axios.get('helper/get_all_costs');
        },
        getStagesPerCost(context,costId){
            return axios.get('helper/get_stages_per_cost/'+costId);
        },
        getCompositionsPerStage(context, stageId){
            return axios.get('helper/get_compositions_per_stage/'+ stageId)
        }


        // getUpdateOrderService(){
        //     return axios.get(`${route}/order_service/get_update_order_service`)
        // },
        // exportOrderService(context,order_service_id){
        //     return axios.get(route + '/order_service/export_pdf/'+order_service_id,{responseType: 'blob'})
        // },
        // exportRemaining(order_service_id){
        //     return axios.get(route + '/order_service/export_pdf/'+order_service_id,{responseType: 'blob'})
        // }

    }
}


