import axios from "axios"
const route ='register/service_group'
const Service = {
    namespaced: true,

    state: () => ({}),
    getters: {},
    mutations: {},
    actions: {
        index(context, {itemsPerPage, page,  search}) {
            return axios.get(route+'?page='+page+'&items_per_page='+itemsPerPage+'&search='+search)
        },

        store(context, data) {
            return axios.post(route, data)
        },
        update(context,data){
            return axios.put(route+'/'+data.id, data)
        },
        destroy(constext, data){
            return axios.delete(route+'/'+data.id)
        },

    }

}


export default Service
