import axios from "axios"

const route = 'register/input_group'
const InputGroup = {
  namespaced: true,

  state: () => ({
    InputGroupId: null
  }),

  getters: {
    getInputGroupId: (state) => { return state.InputGroupId }
  },

  mutations: {
    setInputGroupId: (state, id) => { state.InputGroupId = id },
  },
  actions: {
    syncIntegration() {
      return axios.get(route+'/sync_integration')
    }
  }


}


export default InputGroup
