import axios from "axios"
const route = 'register/service'
const Service = {
    namespaced: true,

    state: () => ({}),
    getters: {},
    mutations: {},
    actions: {
        index(context, { itemsPerPage, page, service_group_id, search }) {
            return axios.get(route + '?page=' + page + '&service_group_id=' + service_group_id + '&items_per_page=' + itemsPerPage + '&search=' + search)
        },
        store(context, payload) {
            return axios.post(route, payload)
        },
        update(context , service){
            return axios.put(route+'/'+service.id,service)
        },
        destroy(context,service) {
            return axios.delete(route+'/'+service.id)
        },
        getCompositions() {
            return axios.get('helper/get_compositions')
        }
    },



}

export default Service
