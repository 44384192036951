<template>
  <v-container>
    <v-data-table :loading="loading_data_table" :headers="headers" :items="inputGroups"
      :pageCount="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
      :options.sync="options" sort-by="corporate_name" class="elevation-1" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Grupo de Insumo</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">

              <v-btn :loading="btnLoadingSync" :disabled="btnLoadingSync" fab small color="primary" v-bind="attrs"
                v-on="on" @click="sendSyncIntegration()"><v-icon>mdi-sync</v-icon></v-btn>
            </template>
            <span>Sincronzar integração </span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-text-field outlined rounded dense v-model="search" append-icon="mdi-magnify" label="Localizar"
            @change="searchItems()" @click:append="searchItems()" single-line hide-details class="mr-8"></v-text-field>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" rounded dark class="mb-2" v-bind="attrs" v-on="on"><v-icon small>mdi-plus</v-icon>
                novo grupo de Insumo</v-btn>
            </template>
            <v-card>
              <div class="title_body">
                <span class="text_title text-h6">{{ formTitle }}</span>
                <span class="btn_close"><v-btn icon dark @click="dialog = false"><v-icon dark> mdi-close
                    </v-icon></v-btn>
                </span>
              </div>
              <v-card-text>
                <v-container>
                  <v-form class="px-3" @submit.prevent="submit()">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field v-model="editedItem.code" label="Codigo"></v-text-field></v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-text-field v-model="editedItem.name" label="Nome"></v-text-field></v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mx-0 mt-3 mr-1" @click="dialog = false">Fechar</v-btn>
                <v-btn class="primary mx-0 mt-3" :loading="btnLoadingSave" :disabled="btnLoadingSave"
                  @click="save">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="text-h5">Tem certeza de que deseja excluir este item ?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-btn class="mr-2" color="primary" outlined @click="getInputs(item)" >Insumos</v-btn> -->

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" v-bind="attrs" v-on="on" @click="getInputs(item)">
              <v-icon >
                mdi-format-list-group
              </v-icon>
            </v-btn>
          </template>
          <span>Lista de Insumos</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" class="mr-2" v-bind="attrs" v-on="on" @click="getInputs(item)">
              <v-icon > mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="error" class="mr-2" v-bind="attrs" v-on="on" @click="deleteItem(item)">
              <v-icon  > mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>



      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>
  </v-container>
</template>


<script>

import service from "./service";
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  data: () => ({
    btnLoadingSync: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      { text: "Codigo", width: true, align: "start", sortable: false, value: "code" },
      { text: "Nome", value: "name" },
      { text: "", align: "end", value: "actions", sortable: false },
    ],
    // desserts: [],
    inputGroups: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {
    ...mapGetters('InputGroup', ['getInputGroupId']),
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Grupo de Insumo" : "Editar Grupo de Insumo";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapMutations('InputGroup', ['setInputGroupId']),
    ...mapActions('InputGroup', ['syncIntegration']),
    initialize() {
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;
      service.getAll(itemsPerPage || 10, page, this.search).then((response) => {
        this.inputGroups = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
    },
    sendSyncIntegration() {
      this.btnLoadingSync = true
      const toast = this.$toast.info('Sincronizando, por favor aguarde!', {
        timeout: false
      })
      this.syncIntegration()
        .then(() => {
          this.$toast.success('Sincronizado com sucesso !')
        }).finally(() => {
          this.$toast.dismiss(toast)
          this.btnLoadingSync = false
        })
    },
    searchItems() {
      this.options = {};
      this.initialize()
    },
    editItem(item) {
      this.editedIndex = this.inputGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.inputGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.inputGroups.splice(editedIndex, 1);
        this.$toast.success("Deletado com sucesso.");
      })

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

      });
    },
    save() {
      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        service.update(this.editedItem).then((response) => {
          this.btnLoadingSave = false;
          Object.assign(this.inputGroups[indice], response.data);
          this.close();
          this.$toast.success("Salvo com sucesso.");
        }).catch(() => {
          this.btnLoadingSave = false;
        })
      } else {
        //store
        service
          .store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.inputGroups.push(response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");
            if (this.inputGroups.length == 1) { this.initialize() }
          })
          .catch(() => {
            this.btnLoadingSave = false;
          })
      }

    },
    getInputs(item) {
      this.setInputGroupId(item.id)
      this.$router.push("/system/register/input")
    }

  },
};
</script>
