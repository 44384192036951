<template>
  <v-container>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-row no-guters>
              <v-col>
                <h2>Controle de Insumos</h2>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-divider vertical></v-divider>
              </v-col>

            </v-row>
          </v-col>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="loadindAtualizarBaixas" :loading="loadindAtualizarBaixas"
                  @click="sincronizarEstoqueBaixas()" fab small color="primary" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-sync
                  </v-icon>
                </v-btn>
              </template>
              <span>Sincronzar integração </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row no-gutters>

          <v-col class="ml-2">
            <v-select multiple  label="Custo" :error-messages="errors.costSelected" v-model="costSelected"
              @change="loadStages()" :items="costs" item-text="name" item-value="id" rounded outlined></v-select>
          </v-col>
          <v-col cols="3">
            <v-select class="ml-2" clearable :loading="loadingStages" :disabled="loadingStages" label="Etapas"
              v-model="stageSelected" :items="stages" item-value="id" rounded outlined></v-select>
          </v-col>
          <v-col cols="2">
            <!-- <v-select :disabled="loading_data_table" @change="carregarOrcamento()" rounded outlined :items="meses"
              v-model="mesSelecionado"></v-select> -->
            <v-text-field class="ml-2" v-model="date" :error-messages="errors.date" type="date" outlined rounded></v-text-field>

          </v-col>
          <v-col cols="2" class="text-right">
            <v-btn x-large color="primary" rounded @click="carregarOrcamento()">APLICAR</v-btn>
          </v-col>
      </v-row>
      <v-row>

        <v-col>
          <v-progress-linear indeterminate color="primary" v-show="loading_data_table"></v-progress-linear>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead class="table">
                <tr>
                  <th colspan="12" class="text-center" style="font-size:20px; color:white;background:#1976d2 ">INSUMOS
                  </th>
                </tr>
                <tr>
                  <th colspan="3" style="color:white;background:#1976d2"></th>
                  <th colspan="3" style="color:white;background:#1976d2" class="text-center">ORÇAMENTO
                  </th>
                  <th colspan="3" style="color:white;background:#1976d2" class="text-center">AVANÇO
                    FÍSICO</th>
                  <th colspan="3" style="color:white;background:#1976d2" class="text-center">SAÍDA
                  </th>
                </tr>
                <tr>
                  <th class="text-center" style="color:white;background:#1976d2">
                    CODIGO
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    INSUMOS
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    UNID
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL (R$)
                  </th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(encontradoNoOrcamento).length > 0">
                <template v-for=" (tipo, key) in encontradoNoOrcamento">
                  <tr :key="key">
                    <td colspan="12" style="background:#e0e0e0 ;">{{ key }}</td>
                  </tr>
                  <tr v-for="item in tipo" :key="item.codigo">
                    <td style="font-size: 13px" class="text-center">{{ item.codigo }}</td>
                    <td style="width: 400px;font-size: 13px;">{{ item.descricao }}</td>
                    <td style="font-size: 13px" class="text-center">{{ item.unidade }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.quantidade_orcamento) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario_orcamento) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario_orcamento * item.quantidade_orcamento) }}
                    </td>

                    <td style="font-size: 13px" class="text-center">
                      <v-edit-dialog :return-value.sync="item.progress_quant">
                        <spam class="editable"> {{ formatMoney(item.progress_quant) }}</spam>
                        <template v-slot:input>
                          <v-text-field v-model="item.progress_quant" :rules="[max25chars]" label="Edit" single-line
                            counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td style="font-size: 13px" class="text-center">
                      <v-edit-dialog :return-value.sync="item.progress_punit">
                        <spam class="editable">{{ formatMoney(item.progress_punit) }}</spam>
                        <template v-slot:input>
                          <v-text-field v-model="item.progress_punit" :rules="[max25chars]" label="Edit" single-line
                            counter></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td style="font-size: 13px" class="text-center">
                      {{ formatMoney(item.progress_punit * item.progress_quant) }}
                    </td>
                    <td style="font-size: 13px" class="text-center">{{ formatMoney(item.quantidade)
                      }}
                    </td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario * item.quantidade) }}</td>
                  </tr>
                </template>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="12" class="text-center">Nenhum dado encontrado</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row>
        <v-col>
          <v-progress-linear indeterminate color="primary" v-show="loading_data_table"></v-progress-linear>
          <v-simple-table dense class="mt-2">
            <template v-slot:default>
              <thead class="table">
                <tr>
                  <th colspan="12" class="text-center" style="font-size:20px; color:white;background:#1976d2 ">INSUMO
                    NÃO ORÇADO</th>

                </tr>

                <tr>
                  <th colspan="3" style="color:white;background:#1976d2"></th>
                  <th colspan="3" class="text-center " style="color:white;background:#1976d2">
                    ORÇAMENTO</th>
                  <th colspan="3" class="text-center " style="color:white;background:#1976d2">AVANÇO
                    FÍSICO</th>
                  <th colspan="3" class="text-center " style="color:white;background:#1976d2">SAÍDA
                  </th>
                </tr>
                <tr>
                  <th class="text-center" style="color:white;background:#1976d2">
                    CODIGO
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    INSUMOS
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    UNID
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    QUANT
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    P UNIT (R$)
                  </th>
                  <th class="text-center" style="color:white;background:#1976d2">
                    SUB-TOTAL (R$)
                  </th>
                </tr>

              </thead>

              <tbody v-if="Object.keys(naoEncontradoNoOrcamento).length > 0">
                <template v-for=" (tipo, key) in naoEncontradoNoOrcamento">
                  <tr :key="key">
                    <td colspan="12" style="background:#e0e0e0 ;">{{ key }}</td>
                  </tr>
                  <tr v-for="item in tipo" :key="item.codigo">
                    <td style="font-size: 13px" class="text-center">{{ item.codigo }}</td>
                    <td style="width: 400px;font-size: 13px;">{{ item.descricao }}</td>
                    <td style="font-size: 13px" class="text-center">{{ item.unidade }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.quantidade_orcamento) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario_orcamento) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario_orcamento * item.quantidade_orcamento) }}
                    </td>
                    <td style="font-size: 13px" class="text-center"></td>
                    <td style="font-size: 13px" class="text-center"></td>
                    <td style="font-size: 13px" class="text-center"></td>
                    <td style="font-size: 13px" class="text-center">{{ formatMoney(item.quantidade)
                      }}
                    </td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario) }}</td>
                    <td style="font-size: 13px" class="text-center">{{
                      formatMoney(item.preco_unitario * item.quantidade) }}</td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12" class="text-center">Nenhum dado encontrado</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <!-- <v-data-table :loading="loading_data_table" :headers="headers" :items="costs" :pageCount="numberOfPagesPaginate"
             :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options"
            class="elevation-1" :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true
            }">
            <template v-slot:item.item="{ item }">
                {{ parseFloat(item.item) }}
            </template>

            <template v-slot:item.value="{ item }">
                {{ formatMoney(item.value) }}
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Controle de Insumos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Localizar" @click:append="searchItems()"
                        @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
                </v-toolbar>
            </template> -->
      <!-- <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" @click="showReportInputPerStage(item)" v-bind="attrs" v-on="on"> mdi-altimeter </v-icon>
                    </template>
                    <span>Etapas</span>
                </v-tooltip>
            </template> -->
      <!-- <template v-slot:no-data> Nenhum registro encontrado </template>
        </v-data-table> -->
    </v-card>
  </v-container>
</template>


<script>

import { mapActions } from 'vuex'
export default {
  data: () => ({
    loadingStages: false,

    loadindAtualizarBaixas: false,
    loading_data_table: false,
    encontradoNoOrcamento: [],
    naoEncontradoNoOrcamento: [],
    search: "",
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    headers: [
      { text: "INSUMOS", value: "input", align: "start", sortable: false },
      { text: "UNID", value: "input", align: "start", sortable: false },
      { text: "QUANT", value: "input", align: "start", sortable: false },
      { text: "P UNIT", value: "input", align: "start", sortable: false },
      { text: "SUB", value: "input", align: "start", sortable: false },
      { text: "", value: "actions", sortable: false, align: "end" },
    ],
    stages: [],
    costs: [],
    costSelected: null,
    stageSelected: null,
    date: null,
    errors: {
      costSelected: null,
      stageSelected: null,
      date: null,
    },
  }),

  computed: {
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions('RelatorioControleInsumos',
      [
        'getEstoqueBaixas',
        'index',
        'getAllCosts',
        'getLoadStages'

      ]),

    initialize() {
      //carrega os custos
      this.getAllCosts().then((response) => {
        this.costs = response.data
      })

      // define a data com a data atual
      this.date = new Date().toISOString().substring(0, 10)


      // //define o mes atual na select mes
      // const date = new Date();
      // let mesAtual = date.getMonth() + 1
      // mesAtual = mesAtual.toString()
      // this.mesSelecionado = mesAtual.padStart(2, '0')

      //  this.carregarOrcamento()

    },

    /**
     * carregar o orcamento
     */
    carregarOrcamento() {
      this.encontradoNoOrcamento = []
      this.naoEncontradoNoOrcamento = []

      //valida os filtros
      //valida o custo
      if (!this.costSelected) {
        this.errors.costSelected = 'Selecione o custo'
        return
      } else {
        this.errors.costSelected = null
      }

      //valida a data
      if (!this.date) {
        this.errors.date = 'Selecione a data'
        return
      } else {
        this.errors.date = null
      }


      //carrega os dados
      this.loading_data_table = true;
      let data = {
        date: this.date,
        cost_id: this.costSelected,
        stage_id: this.stageSelected,
      }
      this.index(data).then((response) => {
        if (response.data.yes) {
          this.encontradoNoOrcamento = response.data.yes
        }
        if (response.data.not) {
          this.naoEncontradoNoOrcamento = response.data.not
          console.log(this.naoEncontradoNoOrcamento)
        }

      }).finally(() => {
        this.loading_data_table = false;
      })
    },

    /**
     * carregar do chessgestao todas as baixas no estoque
     */
    sincronizarEstoqueBaixas() {
      this.loadindAtualizarBaixas = true
      this.getEstoqueBaixas(this.mesSelecionado).then(() => {
        this.$toast.success('Dados atualizado')
        this.carregarOrcamento()
      }).finally(() => {
        this.loadindAtualizarBaixas = false
      })
    },

    /**
     * carrega as etapas
     */
    loadStages() {
      this.loadingStages = true
      this.getLoadStages(this.costSelected).then((response) => {
        this.stages = response.data
      }).finally(() => {
        this.loadingStages = false
      })
    },
    searchItems() {
      this.options = {}
      this.initialize()
    },

    showReportInputPerStage(item) {
      this.setCostId(item.id)
      this.$router.push('/system/report/stage/stages')
    },

    formatMoney(number) {
      number = this.parseNumber(number)
      return number.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },

    parseNumber(number) {
      if (isNaN(number)) { return 0; }
      let result = parseFloat(number);
      return result;
    },

  },
};
</script>
<style scoped>
.table tr th {
  border: solid 1px #e0e0e0;

}

.text-body {
  font-size: 10px;
}

.editable {
  color: #1976d2;
  font-weight: bold;
}
</style>
