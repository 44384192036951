import axios from "axios"

const route = '/physical_progress';

export default {
    namespaced: true,

    state: () => ({
        dialogNewComposition: false
    }),
    getters: {

    },
    mutations: {

    },
    actions: {
        index(context,data){
            let pagination  = `?page=${data.page}&items_per_page=${data.ItemsPerPage||10}&search=${data.search}`
            return axios.get(`${route}/order_service${pagination}`)
        },
        getUpdateOrderService(){
            return axios.get(`${route}/order_service/get_update_order_service`)
        },
        exportOrderService(context,order_service_id){
            return axios.get(route + '/order_service/export_pdf/'+order_service_id,{responseType: 'blob'})
        },
        exportRemaining(context,order_service_id){
            return axios.get(route + '/order_service/remaining/export_pdf/'+order_service_id,{responseType: 'blob'})
        },
        storeConfigIntegration(context, configIntegration ){
            return axios.post(`${route}/order_service/update_or_create_config_integration`,configIntegration)
        },
        loadConfigIntegration(){
            return axios.get(`${route}/order_service/get_config_integration_order_service`)
        },
        //apagar ordem de serviço
        deleteItemService(context,order_service_id){
            console.log(order_service_id)
            return axios.delete(`${route}/order_service/${order_service_id}`)
        }


    }
}


