<template>
  <v-container data-app>
    <v-data-table :loading="loading_data_table" :headers="headers" :items="serviceGroups" :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }" sort-by="corporate_name" class="elevation-1">


      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Grupo de Serviços</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field rounded outlined dense v-model="search" append-icon="mdi-magnify" label="Localizar"
            @click:append="searchItems()" @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
          <v-btn @click="newServiceGroup()" color="primary" rounded dark class="mb-2"><v-icon
              small>mdi-plus</v-icon>Novo Grupo Serviço</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" color="primary" dark v-bind="attrs" v-on="on" @click="editItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" color="secondary" dark v-bind="attrs" v-on="on" @click="showServices(item)">
              <v-icon> mdi-format-list-group </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" color="red" dark v-bind="attrs" v-on="on" @click="deleteItem(item)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>


      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>


    <!-- dialog editar / criar novo serviço -->
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <BarTitle :title="formTitle" @close="dialog = false"></BarTitle>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="2">
                <v-text-field class="mr-2" v-model="editedItem.code" label="Código" outlined></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="editedItem.description" label="Descrição" outlined></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" color="secondary" x-large text>Cancelar</v-btn>
          <v-btn class="primary" x-large @click="save()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog excluir -->
    <MsgDelete :loading="loadingDelete" v-model="dialogDelete" @close="dialogDelete = false" @confirm="deleteItemConfirm"></MsgDelete>

  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import MsgDelete from '@/pages/Components/MsgDelete.vue';
import BarTitle from '@/pages/Components/BarTitle.vue';
export default {
  components: {
    BarTitle,
    MsgDelete
  },
  data: () => ({
    loadingDelete: false,
    dialogDelete: false,
    btnLoadingSave:false,
    dialog: false,
    editedItem: {
      code: '',
      description: ''
    },
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,

    options: {},
    formTitle: 'dsfsdf',
    search: '',
    headers: [
      { text: 'CODIGO', value: 'code' },
      { text: 'SERVIÇO', value: 'description' },
      { text: '', align: 'right', value: 'actions', sortable: false }
    ],
    serviceGroups: [],

  }),
  created() {
    this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
  },
  methods: {
    ...mapActions('ServiceGroup', ['index', 'store', 'update', 'destroy']),

    initialize() {
      //carrega a tabela
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;

      let params = {
        page,
        itemsPerPage: itemsPerPage || 10,
        search:this.search
      }


      this.index(params).then((response) => {
        this.serviceGroups = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });

      this.loadingCompositionGroup = true

    },
    newServiceGroup() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, {
        code: '',
        description: ''
      });
      this.formTitle = 'Novo Grupo de Serviço';
      this.dialog = true;
    },
    save() {

      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        this.update(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            Object.assign(this.serviceGroups[indice], response.data);
            this.dialog = false;
            this.$toast.success("Salvo com sucesso.");
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      } else {
        //store
        this.store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.serviceGroups.push(response.data);

            this.dialog = false;
            this.$toast.success("Salvo com sucesso.");
            if (this.serviceGroups.length == 1) { this.initialize() }
          })
          .catch(() => {
            this.btnLoadingSave = false;
          });
      }
    },
    editItem(item) {
      this.formTitle = 'Editar Grupo de Serviço';
      this.editedIndex = this.serviceGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.serviceGroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      this.loadingDelete = true;
      this.destroy(this.editedItem).then(() => {
        this.serviceGroups.splice(editedIndex, 1);
        this.$toast.success("Excluído com sucesso.");
        this.dialogDelete = false;
      }).finally(() => {
        this.loadingDelete = false;
      });
    },
    showServices(item){
        this.$router.push({ name: 'service', params: { service_group_id: item.id } });
    }

  }
};
</script>
