<template>
  <v-dialog v-model="value">
    <v-card :loading="loadingMain" :disabled="loadingMain">
      <template slot="progress">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>

      <BarTitle @close="close()" title="MEDIÇÃO" />

      <v-card-actions>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon x-large color="primary" outlined v-bind="attrs" v-on="on"><v-icon> mdi-menu
              </v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title @click="exportPdf()"><v-icon>mdi-file-pdf-box</v-icon> Exportar
                Pdf</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-space></v-space>
      </v-card-actions>
      <!-- <v-card-actions>
      <v-row>
        <v-col class="text-left">
          <v-btn :loading="loadingApproved" :disabled="loadingApproved" @click="approved()" x-large color="success">aprovar</v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn text x-large>fechar</v-btn>
          <v-btn :loading="loadingSave" :disabled="loadingSave" @click="save('close')" x-large color="primary">SALVAR</v-btn>
        </v-col>
      </v-row>
    </v-card-actions> -->
      <!-- <v-divider class="mb-4"></v-divider> -->
      <v-card-text>
        <v-row>
          <v-col><b>SUB-EMPRETEIRO :</b> {{ contractor.fantasy_name }}</v-col>
          <v-col><b>PERÍODO :</b> {{ formatDate(contract.date_start) + ' - ' +
            formatDate(contract.date_end) }}</v-col>
        </v-row>
        <v-row>
          <v-col><b>MEDICÃO N°:</b>{{ contract.metering_n }}</v-col>

          <v-col v-if="contract.date_approved"><b>DATA:</b> {{ formatDateTime(contract.date_approved) }} <b>Por:</b> {{ approvedBy.name }}</v-col>
          <!-- <v-col v-else ><b>DATA:</b>  <b>Por:</b> </v-col> -->
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table dense :headers="headers" :items="contractSummaryItems" :items-per-page="5"
                class="elevation-0">
                <!-- QUANTIDADE -->
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>

                <!-- PRECO UNITARIO -->
                <template v-slot:item.unit_price="{ item }">
                  {{ formatMoney(item.unit_price) }}
                </template>

                <template v-slot:item.sub_total="{ item }">
                  {{ formatMoney(item.sub_total) }}
                </template>
                <template v-slot:no-data> Nenhum item na lista. </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn color="error" icon @click="removeService(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:foot>
                  <tr>
                    <td style="background: lightgray; height: 35px" class="text-right" colspan="6">
                      SUB-TOTAL:
                    </td>
                    <td style="
                        background: lightgray;
                        height: 35px;
                        padding-left: 16px;
                        padding-right: 16px;
                      " class="text-right">
                      <b>{{ formatMoney(subtotalContractorItems()) }}</b>
                    </td>
                  </tr>
                  <!-- <tr>
                                    <td class="text-center " colspan='8'>
                                        <v-btn @click="addService()" class="mb-4 mt-4" rounded color="primary">
                                            <v-icon>mdi-plus</v-icon>ADICIONAR SERVIÇO
                                        </v-btn>
                                    </td>
                                </tr> -->
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="cols-4">
            <v-textarea v-model="contract.obs" rows="11" label="Observações" class="" outlined></v-textarea>
          </v-col>
          <v-col>
            <v-card outlined style="font-size: 16px">
              <v-row style="font-size: 18px; background: #1976d2; color: white; height: 30px" no-gutters>
                <v-col><b class="ml-2">SUBTOTAL 1</b></v-col>
                <v-col class="text-right" style="font-size: 14px;">QUANT</v-col>
                <v-col class="text-right" style="font-size: 14px;">P. UNIT</v-col>
                <v-col class="text-right"><b class="mr-2">{{ formatMoney(subtotalContractorItems())
                    }}</b></v-col>
              </v-row>
              <v-row v-for="item in metering_contract_expenses" :key="item.description"
                style="background: lightgray; height: 25px" no-gutters>
                <v-col>
                  <!-- <v-edit-dialog :return-value.sync="item.description" large cancel-text="fechar" save-text="salvar"> -->
                  <div class=" ml-2">{{ item.description }}</div>
                  <!-- <template v-slot:input>
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <!-- <v-edit-dialog @save="calcTotal()" :return-value.sync="item.base" large cancel-text="fechar" -->
                  <!-- save-text="salvar"> -->
                  <div class="">{{ formatMoney(item.base) }}</div>
                  <!-- <template v-slot:input>
                      <vuetify-money v-model="item.base" :clearable="true" valueWhenIsEmpty="0" :options="{
                        locale: 'pt-BR',
                        length: 11,
                        precision: 2,
                      }" />
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <!-- <v-edit-dialog @save="calcTotal()" :return-value.sync="item.fator" large cancel-text="fechar"
                    save-text="salvar"> -->
                  <div class="">{{ formatMoney(item.fator) }}</div>
                  <!-- <template v-slot:input>
                      <vuetify-money v-model="item.fator" :clearable="true" valueWhenIsEmpty="0" :options="{
                        locale: 'pt-BR',
                        length: 11,
                        precision: 2,
                      }" />
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                </v-col>
              </v-row>

              <v-row style="font-size: 18px; background: #1976d2; color: white; height: 30px" no-gutters>
                <v-col><b class="ml-2">SUBTOTAL 2</b></v-col>
                <v-col class="text-right" style="font-size: 14px;">BASE</v-col>
                <v-col class="text-right" style="font-size: 14px;">ALÍQUOTA</v-col>
                <v-col class="text-right"><b class="mr-2">{{ formatMoney(subTotal2) }}</b></v-col>
              </v-row>
              <v-row v-for="(item, index) in metering_contract_taxes" :key="index"
                style="background: lightgray; height: 25px" no-gutters>
                <v-col>
                  <!-- <v-edit-dialog :return-value.sync="item.description" large cancel-text="fechar" save-text="salvar"> -->
                  <div class="ml-2">{{ item.description }}</div>
                  <!-- <template v-slot:input>
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <!-- <v-edit-dialog @save="calcTotal()" :return-value.sync="item.base" large cancel-text="fechar"
                    save-text="salvar"> -->
                  <div class="">{{ formatMoney(item.base) }}</div>
                  <!-- <template v-slot:input>
                      <vuetify-money v-model="item.base" :clearable="true" valueWhenIsEmpty="0" :options="{
                        locale: 'pt-BR',
                        length: 11,
                        precision: 2,
                      }" />
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <!-- <v-edit-dialog @save="calcTotal()" :return-value.sync="item.fator" large cancel-text="fechar"
                    save-text="salvar"> -->
                  <div class="">{{ formatMoney(item.fator) }} %</div>
                  <!-- <template v-slot:input>
                      <vuetify-money v-model="item.fator" :clearable="true" valueWhenIsEmpty="0" :options="{
                        locale: 'pt-BR',
                        length: 11,
                        precision: 2,
                      }" />
                    </template>
                  </v-edit-dialog> -->
                </v-col>
                <v-col class="text-right">
                  <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                </v-col>
              </v-row>
              <v-row no-gutters style="
                  font-size: 18px;
                  font-weight: bold;
                  background: #1976d2;
                  color: white;
                  height: 30px;
                ">
                <v-col class="ml-2">TOTAL:</v-col>
                <v-col class="text-right mr-2"> {{ formatMoney(total) }}</v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import {
  mapActions
} from "vuex";
import units from "@/plugins/units.js";

export default {
  props: {
    value: {
      type: Boolean,
    },
    contract: {
      type: Object,
    },
  },

  components: {
    BarTitle,
  },

  data: () => ({
    total: 0,
    subTotal2: 0,
    subTotal1: 0,
    itemsUnits: units.units,
    loadingMain: false,
    loadingSave: false,
    loadingApproved: false,
    contractor: {},
    approvedBy: {},
    contract: {
      obs: "",
    },
    contractSummaryItems: [],
    metering_contract_item_deleted: [],

    metering_contract_expenses: [],
    metering_contract_taxes: [],

    headers: [{
      text: "ITEM",
      value: "item",
    },
    {
      text: "CC",
      value: "cc",
    },
    {
      text: "SERVIÇO",
      value: "service",
    },
    {
      text: "UND",
      value: "unity",
    },
    {
      text: "QUANTIDADE",
      value: "quantity",
      align: "right",
    },
    {
      text: "P. UNIT",
      value: "unit_price",
      align: "right",
    },
    {
      text: "SUB-TOTAL",
      value: "sub_total",
      align: "right",
    },
      // {
      //     text: '',
      //     value: 'actions',
      //     aling: 'end',
      // }
    ],
    headers_subtotal1: [{
      text: "SUB-TOTAL 1",
      value: "",
    },
    {
      text: "",
      value: "",
    },
    {
      text: "",
      value: "",
    },
    {
      text: "",
      value: "",
    },
    ],
  }),

  watch: {
    value(val) {
      if (val) {
        this.initialize();
      }
    },
  },
  computed: {},

  methods: {
    ...mapActions("Contractor", [
      "getContractSummary",
      "updateCreateContractorSummary",
      "approvedServices",
      'getExportPdf'
    ]),
    initialize() {
      console.log(this.contract)
      //define items depesas padrao
      this.metering_contract_expenses = [{
        description: "ALMOÇO",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "CAFÉ",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "EPI",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "OUTROS",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      ];

      // define items de impostos padrao
      this.metering_contract_taxes = [{
        description: "ISS (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "INSS (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "PIS (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "COFINS (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "CSLL (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      {
        description: "IR (%)",
        base: 0,
        fator: 0,
        sub_total: 0,
      },
      ];
      //limpa o array de item deletados
      this.metering_contract_item_deleted = [];

      this.loadingMain = true;

      this.getContractSummary(this.contract.id)
        .then((response) => {
          //

          this.contract = response.data;
          this.contractor = response.data.contractor;
          this.approvedBy = response.data.approved_by_user;

          this.contractSummaryItems = this.contract.metering_contract_item;

          if (this.contract.metering_contract_expenses.length > 0) {
            this.metering_contract_expenses = this.contract.metering_contract_expenses;
          }

          if (this.contract.metering_contract_taxes.length > 0) {
            this.metering_contract_taxes = this.contract.metering_contract_taxes;
          }
          this.calcTotal();

        })
        .finally(() => {
          this.loadingMain = false;
        });
    },
    exportPdf() {
      this.getExportPdf(this.contractor)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'export_summary_contractor.pdf');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .finally(() => { });

      this.$toast.success("Exportando PDF");
    },
    close() {
      this.$emit("input", false);
    },

    subtotalContractorItems() {
      const result = this.contractSummaryItems.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      return result;
    },

    setPeriod() {
      if (this.contract) {
        this.dateStart = this.contract.items[0].date_start;
        this.dateEnd = this.contract.items[0].date_end;
      }
    },
    /**
     * SALVAR O CONTRATO E SEUS ITEMS
     */
    save($param) {
      this.loadingSave = true;

      const data = {
        contractor_id: this.contractor.id,
        obs: this.contract.obs,
        metering_contract_expenses: this.metering_contract_expenses,
        metering_contract_taxes: this.metering_contract_taxes,
      };
      this.updateCreateContractorSummary(data)
        .then(() => {
          this.$toast.success("Salvo com sucesso.");

          switch ($param) {
            case 'close':
              this.$emit("input", false);
              break;
            case 'approved':
              this.sendApproved()
              break;
          }

        })
        .finally(() => {
          this.loadingSave = false;
        });

      // this.contract.local_metering_id = this.metering.id
      // this.contract.metering_contract_item = this.metering_contract_item
      // this.contract.metering_contract_expenses = this.metering_contract_expenses
      // this.contract.metering_contract_taxes = this.metering_contract_taxes

      // const data = {
      //     contract: this.contract,
      //     metering_contract_item_deleted: this.metering_contract_item_deleted,
      // }

      // this.updateCreateMeteringContract(data).then(() => {
      //         this.$toast.success('Salvo com sucesso')
      //         this.close()
      //     })
      //     .finally(() => {
      //         this.loadingSave = false
      //     })
    },
    /**
     * APROVAR SERVIÇOS DO EMREITEIRO EM UM PERIODO ESPECIFICO
     */

    approved() {
      this.save('approved')
    },
    sendApproved() {
      this.loadingApproved = true
      this.approvedServices(this.contractor.id)
        .then(() => {
          //fechar o modal de controle de medicoes
          this.$emit('refreshTable')
          this.close();
          this.$toast.success("Controle de medição aprovado. ");
        }).finally(() => {
          this.loadingApproved = false
        });
    },
    /**
     * ADICIONA NOVO SERVIÇO
     */
    addService() {
      const service = {
        order: 0,
        contract_code: 0,
        description: "Serviço",
        unit: "unidade",
        quantity: 0,
        unit_price: 0,
        sub_total: 0,
      };

      this.metering_contract_item.push(Object.assign({}, service));
    },

    /**
     * REMOVE UM ITEM DE SERVICOS
     */
    removeService(service) {
      this.metering_contract_item_deleted.push(Object.assign({}, service));
      const index = this.metering_contract_item.indexOf(service);
      this.metering_contract_item.splice(index, 1);
    },

    /**
     *CALCULA OS SUB-TOTAIS DE CADA SERVIÇO
     */
    calcTotal() {
      //sub total de cada linha do SUBTOTAL1
      this.metering_contract_expenses.forEach((item) => {
        item.sub_total = parseFloat(item.base) * parseFloat(item.fator);
      });

      //sub total de cada linha do SUBTOTAL2
      this.metering_contract_taxes.forEach((item) => {
        item.sub_total = parseFloat(item.base) * (parseFloat(item.fator) / 100);
      });

      //somatorio de subtotais do SUBTOTAL1
      let sumSubtotal1 = this.metering_contract_expenses.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      this.subTotal2 = this.subtotalContractorItems() - parseFloat(sumSubtotal1);

      //somatorio de subtotais do SUBTOTAL2
      let sumSubtotal2 = this.metering_contract_taxes.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      //total
      this.total = parseFloat(this.subTotal2) - parseFloat(sumSubtotal2);
    },

    /**
     * FORMATA FLOAT PARA MONETARIO
     * @param number
     */
    formatMoney(number) {
      if (isNaN(number)) {
        return 0;
      }
      number = parseFloat(number);

      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    /**
     *formata data para padrao BR
     * @param date
     */
    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },

    formatDateTime(date) {
      if (date) {
        date = new Date(date)
        let dateTime = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() + ' '
        dateTime += date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
        return dateTime
      }

      return ''
    }
  },
};
</script>

<style scoped>
.editable {
  color: #1976d2;
  font-weight: bold;
}
</style>
