<template>
  <v-container data-app>
    <v-data-table :loading="loading_data_table" :headers="headers" :items="services" :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      }" sort-by="corporate_name" class="elevation-1">


      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Serviços</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field rounded outlined dense v-model="search" append-icon="mdi-magnify" label="Localizar"
            @click:append="searchItems()" @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
          <v-btn @click="newService()" color="primary" rounded dark class="mb-2"><v-icon small>mdi-plus</v-icon>Novo
            Serviço</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" color="primary" dark v-bind="attrs" v-on="on" @click="editItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="item.integration_id != null" icon class="mr-2" color="red" v-bind="attrs" v-on="on"
              @click="deleteItem(item)">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>


      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>


    <!-- dialog editar / criar novo serviço -->
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <BarTitle :title="formTitle" @close="dialog = false"></BarTitle>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="2">
                <v-text-field class="mr-2" v-model="editedItem.service_group_id" label="Grupo" outlined></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field class="mr-2" v-model="editedItem.code" label="Código" outlined></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="editedItem.description" label="Descrição" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-select :items="unitItems" v-model="editedItem.unit_of_measure" class="mr-2" label="Unidade" outlined
                  outilined></v-select>
              </v-col>
              <v-col>
                <v-text-field v-model="editedItem.unit_cost" label="Custo Unitario" outlined></v-text-field>
              </v-col>

            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-select class="mr-2" label="Fornecedor" outlined></v-select>
              </v-col>
              <v-col>
                <v-text-field type="date" v-model="editedItem.cost_validity" label="Validade" outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea v-model="editedItem.comments" label="Observações" outlined>{{ editedItem.comments
                  }}</v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-select :loading="loadingComposition" :disabled="loadingComposition" :items="compositions"
                  v-model="editedItem.integration_composition_id" item-value="id" item-text="name" label="Composição"
                  outlined></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" color="secondary" x-large text>Cancelar</v-btn>
          <v-btn class="primary" x-large @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" :loading="loadingDelete" @confirm="deleteItemConfirm()"
      @close="dialogDelete = false">
    </MsgDelete>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import units from "@/plugins/units.js";
import BarTitle from '@/pages/Components/BarTitle.vue';
import MsgDelete from '@/pages/Components/MsgDelete.vue';

export default {
  components: {
    BarTitle,
    MsgDelete
  },
  data: () => ({
    loadingDelete: false,
    dialogDelete: false,
    loadingComposition: false,
    dialog: false,
    editedItem: {
      code: '',
      description: ''
    },
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,

    options: {},
    formTitle: '',
    search: '',
    headers: [
      { text: 'CODIGO', value: 'code' },
      { text: 'SERVIÇO', value: 'description' },
      { text: '', align: 'right', value: 'actions', sortable: false }
    ],
    services: [],
    unitItems: units.units,
    compositions: [],
  }),
  created() {
    //carrega composicoes
    this.loadingComposition = true;
    this.getCompositions().then((response) => {
      this.compositions = response.data;
    }).finally(() => {
      this.loadingComposition = false;
    });
    this.initialize();
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
  },
  methods: {
    ...mapActions('Service', ['index', 'store', 'update', 'destroy', 'getCompositions']),

    initialize() {

      //carrega a tabela
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;

      let params = {
        page,
        itemsPerPage: itemsPerPage || 10,
        service_group_id: this.$route.params.service_group_id,
        search: this.search,
      }

      this.index(params).then((response) => {
        this.services = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
      this.loadingCompositionGroup = true

    },
    newService() {
      this.editedIndex = -1;
      this.formTitle = 'Novo Serviço';
      this.editedItem = {
        code: '',
        description: '',
        unit_of_measure: '',
        unit_cost: '',
        cost_validity: '',
        comments: '',
        integration_composition_id: '',
        service_group_id: this.$route.params.service_group_id
      }
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {

      this.loadingDelete = true;
      this.destroy(this.editedItem).then(() => {
        this.services.splice(this.editedIndex, 1);
        this.$toast.success("Excluído com sucesso.");
      }).finally(() => {
        this.loadingDelete = false;
        this.dialogDelete = false;
      });

      //   this.closeDelete();
    },
    save() {
      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        this.update(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            Object.assign(this.services[indice], response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");
            this.dialog = false
          })
          .finally(() => {
            this.btnLoadingSave = false;
          });
      } else {
        //store
        this.store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.services.push(response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");
            if (this.services.length == 1) { this.initialize() }
            this.dialog = false

          })
          .finally(() => {
            this.btnLoadingSave = false;
          });
      }
    },
  }
};
</script>
