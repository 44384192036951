import axios from "axios"

const route = '/physical_progress';

export default {
    namespaced: true,

    state: () => ({
        dialogNewComposition: false
    }),
    getters: {

    },
    mutations: {

    },
    actions: {
        index(context, data) {
            let pagination = `?page=${data.page}&items_per_page=${data.ItemsPerPage || 10}&search=${data.search}`
            return axios.get(`${route}/metering${pagination}`)
        },


        //contractor summary
        getContractSummary(context, contractor_id) {
            return axios.get(route + '/metering/contractor_summary/' + contractor_id)
        },

        updateCreateContractorSummary(context, data) {
            return axios.post(route + '/metering/update_create_contractor_summary/' + data.contractor_id,data)
        },

        approvedServices(context,contractor_id){
            return axios.post(route + '/metering/approved_services/' + contractor_id)
        },

        //historico das medicoes
        getHistoricMetering(){
            return axios.get(route + '/metering/historic_metering')
        },

        //exportar pdf
        getExportPdf(context,contractor){
            return axios.get(route + '/metering/export_pdf/' + contractor.id,{responseType: 'blob'})
        }


        // getUpdateOrderService(){
        //     return axios.get(`${route}/order_service/get_update_order_service`)
        // },
        // exportOrderService(context,order_service_id){
        //     return axios.get(route + '/order_service/export_pdf/'+order_service_id,{responseType: 'blob'})
        // },
        // exportRemaining(order_service_id){
        //     return axios.get(route + '/order_service/export_pdf/'+order_service_id,{responseType: 'blob'})
        // }

    }
}


