<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-card>
      <BarTitle @close="closeDialog()" title="Adicionar Serviço"></BarTitle>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select @change="getStages()" v-model="cost" :items="costs" return-object item-value="id"
                item-text="name" outlined label="Custo"></v-select>
            </v-col>
            <v-col>
              <v-select @change="getCompositions()" v-model="stage" :items="stages" item-value="id" return-object
                outlined label="Etapa"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="composition" :items="compositions" item-value="id" return-object outlined
                label="Composição"></v-select>
            </v-col>
            <v-col>
              <vuetify-money outlined v-model="quantity" :clearable="true" valueWhenIsEmpty="0" :options="{
                locale: 'pt-BR',
                length: 11,
                precision: 2,
              }" />
              <!-- <v-text-field v-model="quantity" outlined label="Quantidade"></v-text-field> -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mr-2" x-large color="secondary" text @click="closeDialog()">Cancelar</v-btn>
        <v-btn color="primary" x-large @click="saveService()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BarTitle from '@/pages/Components/BarTitle.vue';
import { mapActions } from 'vuex';
import { mapMutations } from 'vuex';
export default {
  components: {
    BarTitle
  },
  props: {
    value: { type: Boolean }
  },
  data() {
    return {
      dialog: false,
      cost: null,
      costs: [],
      stages: [],
      stage: null,
      compositions: [],
      composition: null,
      quantity: null,
    };
  },
  watch: {
    value(val) {
      this.dialog = val
      //limpa os campos
      this.cost = {},
        this.stage = {},
        this.composition = {},
        this.quantity = null,

        //carrega custos
        this.getCost().then((response) => {
          this.costs = response.data
        })
    },
  },

  methods: {
    ...mapActions('MeteringDetached',
      [
        'getCost',
        'getStagesPerCost',
        'getCompositionsPerStage',
      ]),
    ...mapMutations('MeteringDetached', ['addService']),
    closeDialog() {
      this.$emit('input', false)
      this.dialog = false
    },

    getStages() {

      this.getStagesPerCost(this.cost.id).then((response) => {
        this.stages = response.data
      })
    },

    getCompositions() {
      this.getCompositionsPerStage(this.stage.id).then((response) => {
        this.compositions = response.data
      })
    },

    saveService() {
      //validar campos
      // console.log(this.cost.id)
      if(this.cost.id == undefined || this.stage.id == undefined || this.composition.id == undefined ){
        this.$toast.error('Todos os campos são obrigatórios')
        return false
      }

      if(this.quantity <= 0){
        this.$toast.error('Quantidade deve ser maior que zero.')
        return false
      }


      let service = {
        cost: this.cost,
        stage: this.stage,
        composition: this.composition,
        quantity: this.quantity
      }
      this.addService(service)

      this.closeDialog()
    },


  },




};
</script>

<style scoped>
/* Add your styles here */
</style>
